import React from 'react'
import Layout from 'components/Layout'
import Container from 'components/Container'
import { graphql } from 'gatsby'

export default function LegalNotice({ data: { site } }) {
  return (
    <Layout site={site}>
      <Container>
        <h1>Privacy policy</h1>
        <p>
          {' '}
          This privacy policy (&quot;Policy&quot;) describes how the personally
          identifiable information (&quot;Personal Information&quot;) you may
          provide on the{' '}
          <a rel="nofollow" href="https://notes.sebastianauner.com">
            notes.sebastianauner.com
          </a>{' '}
          website (&quot;Website&quot; or &quot;Service&quot;) and any of its
          related products and services (collectively, &quot;Services&quot;) is
          collected, protected and used. It also describes the choices available
          to you regarding our use of your Personal Information and how you can
          access and update this information. This Policy is a legally binding
          agreement between you (&quot;User&quot;, &quot;you&quot; or
          &quot;your&quot;) and this Website operator (&quot;Operator&quot;,
          &quot;we&quot;, &quot;us&quot; or &quot;our&quot;). By accessing and
          using the Website and Services, you acknowledge that you have read,
          understood, and agree to be bound by the terms of this Policy. This
          Policy does not apply to the practices of companies that we do not own
          or control, or to individuals that we do not employ or manage.
        </p>
        <h2>Collection of information</h2>
        <p>
          Our top priority is customer data security and, as such, we exercise
          the no logs policy. We may process only minimal user data, only as
          much as it is absolutely necessary to maintain the Website and
          Services. Information collected automatically is used only to identify
          potential cases of abuse and establish statistical information
          regarding the usage and traffic of the Website and Services. This
          statistical information is not otherwise aggregated in such a way that
          would identify any particular user of the system.
        </p>
        <h2>Use and processing of collected information</h2>
        <p>
          In order to make the Website and Services available to you, or to meet
          a legal obligation, we may need to collect and use certain Personal
          Information. If you do not provide the information that we request, we
          may not be able to provide you with the requested products or
          services. Any of the information we collect from you may be used to
          help us run and operate the Website and Services.
        </p>
        <p>
          Processing your Personal Information depends on how you interact with
          the Website and Services, where you are located in the world and if
          one of the following applies: (i) you have given your consent for one
          or more specific purposes; this, however, does not apply, whenever the
          processing of Personal Information is subject to European data
          protection law; (ii) provision of information is necessary for the
          performance of an agreement with you and/or for any pre-contractual
          obligations thereof; (iii) processing is necessary for compliance with
          a legal obligation to which you are subject; (iv) processing is
          related to a task that is carried out in the public interest or in the
          exercise of official authority vested in us; (v) processing is
          necessary for the purposes of the legitimate interests pursued by us
          or by a third party.
        </p>
        <p>
          {' '}
          Note that under some legislations we may be allowed to process
          information until you object to such processing (by opting out),
          without having to rely on consent or any other of the following legal
          bases below. In any case, we will be happy to clarify the specific
          legal basis that applies to the processing, and in particular whether
          the provision of Personal Information is a statutory or contractual
          requirement, or a requirement necessary to enter into a contract.
        </p>
        <h2>Disclosure of information</h2>
        <p>
          {' '}
          The maintain the highest level of privacy and protecting your Personal
          Information to the full extent, we do not share your Personal
          Information with anyone and for any reason.
        </p>
        <h2>Retention of information</h2>
        <p>
          We will retain and use your Personal Information for the period
          necessary to comply with our legal obligations, resolve disputes, and
          enforce our agreements unless a longer retention period is required or
          permitted by law. We may use any aggregated data derived from or
          incorporating your Personal Information after you update or delete it,
          but not in a manner that would identify you personally. Once the
          retention period expires, Personal Information shall be deleted.
          Therefore, the right to access, the right to erasure, the right to
          rectification and the right to data portability cannot be enforced
          after the expiration of the retention period.
        </p>
        <h2>The rights of users</h2>
        <p>
          You may exercise certain rights regarding your information processed
          by us. In particular, you have the right to do the following: (i) you
          have the right to withdraw consent where you have previously given
          your consent to the processing of your information; (ii) you have the
          right to object to the processing of your information if the
          processing is carried out on a legal basis other than consent; (iii)
          you have the right to learn if information is being processed by us,
          obtain disclosure regarding certain aspects of the processing and
          obtain a copy of the information undergoing processing; (iv) you have
          the right to verify the accuracy of your information and ask for it to
          be updated or corrected; (v) you have the right, under certain
          circumstances, to restrict the processing of your information, in
          which case, we will not process your information for any purpose other
          than storing it; (vi) you have the right, under certain circumstances,
          to obtain the erasure of your Personal Information from us; (vii) you
          have the right to receive your information in a structured, commonly
          used and machine readable format and, if technically feasible, to have
          it transmitted to another controller without any hindrance. This
          provision is applicable provided that your information is processed by
          automated means and that the processing is based on your consent, on a
          contract which you are part of or on pre-contractual obligations
          thereof.
        </p>
        <h2>Privacy of children</h2>
        <p>
          We do not knowingly collect any Personal Information from children
          under the age of 18. If you are under the age of 18, please do not
          submit any Personal Information through the Website and Services. We
          encourage parents and legal guardians to monitor their children's
          Internet usage and to help enforce this Policy by instructing their
          children never to provide Personal Information through the Website and
          Services without their permission. If you have reason to believe that
          a child under the age of 18 has provided Personal Information to us
          through the Website and Services, please contact us. You must also be
          old enough to consent to the processing of your Personal Information
          in your country (in some countries we may allow your parent or
          guardian to do so on your behalf).
        </p>
        <h2>Do Not Track signals</h2>
        <p>
          Some browsers incorporate a Do Not Track feature that signals to
          websites you visit that you do not want to have your online activity
          tracked. Tracking is not the same as using or collecting information
          in connection with a website. For these purposes, tracking refers to
          collecting personally identifiable information from consumers who use
          or visit a website or online service as they move across different
          websites over time. How browsers communicate the Do Not Track signal
          is not yet uniform. As a result, the Website and Services are not yet
          set up to interpret or respond to Do Not Track signals communicated by
          your browser. Even so, as described in more detail throughout this
          Policy, we limit our use and collection of your personal information.
        </p>
        <h2>Links to other resources</h2>
        <p>
          The Website and Services contain links to other resources that are not
          owned or controlled by us. Please be aware that we are not responsible
          for the privacy practices of such other resources or third parties. We
          encourage you to be aware when you leave the Website and Services and
          to read the privacy statements of each and every resource that may
          collect Personal Information.
        </p>
        <h2>Information security</h2>
        <p>
          We secure information you provide on computer servers in a controlled,
          secure environment, protected from unauthorized access, use, or
          disclosure. We maintain reasonable administrative, technical, and
          physical safeguards in an effort to protect against unauthorized
          access, use, modification, and disclosure of Personal Information in
          its control and custody. However, no data transmission over the
          Internet or wireless network can be guaranteed. Therefore, while we
          strive to protect your Personal Information, you acknowledge that (i)
          there are security and privacy limitations of the Internet which are
          beyond our control; (ii) the security, integrity, and privacy of any
          and all information and data exchanged between you and the Website and
          Services cannot be guaranteed; and (iii) any such information and data
          may be viewed or tampered with in transit by a third party, despite
          best efforts.
        </p>
        <h2>Data breach</h2>
        <p>
          In the event we become aware that the security of the Website and
          Services has been compromised or users Personal Information has been
          disclosed to unrelated third parties as a result of external activity,
          including, but not limited to, security attacks or fraud, we reserve
          the right to take reasonably appropriate measures, including, but not
          limited to, investigation and reporting, as well as notification to
          and cooperation with law enforcement authorities. In the event of a
          data breach, we will make reasonable efforts to notify affected
          individuals if we believe that there is a reasonable risk of harm to
          the user as a result of the breach or if notice is otherwise required
          by law. When we do, we will post a notice on the Website.
        </p>
        <h2>Changes and amendments</h2>
        <p>
          We reserve the right to modify this Policy or its terms relating to
          the Website and Services from time to time in our discretion and will
          notify you of any material changes to the way in which we treat
          Personal Information. When we do, we will revise the updated date at
          the bottom of this page. We may also provide notice to you in other
          ways in our discretion, such as through contact information you have
          provided. Any updated version of this Policy will be effective
          immediately upon the posting of the revised Policy unless otherwise
          specified. Your continued use of the Website and Services after the
          effective date of the revised Policy (or such other act specified at
          that time) will constitute your consent to those changes. However, we
          will not, without your consent, use your Personal Information in a
          manner materially different than what was stated at the time your
          Personal Information was collected.
        </p>
        <h2>Acceptance of this policy</h2>
        <p>
          You acknowledge that you have read this Policy and agree to all its
          terms and conditions. By accessing and using the Website and Services
          you agree to be bound by this Policy. If you do not agree to abide by
          the terms of this Policy, you are not authorized to access or use the
          Website and Services. This privacy policy was created with the{' '}
          <a href="https://www.websitepolicies.com/privacy-policy-generator">
            privacy policy generator
          </a>
          .
        </p>
        <h2>Contacting us</h2>
        <p>
          If you would like to contact us to understand more about this Policy
          or wish to contact us concerning any matter relating to individual
          rights and your Personal Information, you may send an email to
          notes&#64;sebastianauner.com.
        </p>
        <p>This document was last updated on June 17, 2022</p>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
  }
`
